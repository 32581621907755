<template>
  <div class="consoleBox">
    <div class="menu-navBox">
      <el-menu
        class="menu-nav"
        background-color="#15111B"
        text-color="#81818C"
        active-text-color="#fff"
        :default-active="activeRouter"
        unique-opened
        router
      >
        <el-menu-item class="menu-navItem" index="/console/goldIdentification/list">
          <svg class="navIcon" aria-hidden="true">
            <use
              v-show="activeRouter=='/console/goldIdentification/list'"
              xlink:href="#iconjuxing23kaobei-copy"
            />
            <use
              v-show="activeRouter!='/console/goldIdentification/list'"
              xlink:href="#iconjuxing23kaobei"
            />
          </svg>
          <span>金标认证</span>
        </el-menu-item>
        <!-- <el-menu-item class="menu-navItem" index="/console/application/list">
          <svg class="navIcon" aria-hidden="true">
            <use
              v-show="activeRouter=='/console/application/list'"
              xlink:href="#iconxingzhuang1-copy"
            />
            <use v-show="activeRouter!='/console/application/list'" xlink:href="#iconxingzhuang1" />
          </svg>
          <span slot="title">应用信息</span>
        </el-menu-item> -->
         <el-menu-item class="menu-navItem" index="/console/cloudServices">
          <svg class="navIcon" aria-hidden="true">
            <use
              v-show="activeRouter=='/console/cloudServices'"
              xlink:href="#iconceshifuzhu-copyceshi"
            />
            <use v-show="activeRouter!='/console/cloudServices'" xlink:href="#iconceshifuzhu-copy" />
          </svg>
          <span slot="title">云测服务</span>
        </el-menu-item>
        <el-menu-item class="menu-navItem" index="/console/allianceMembers/bind">
          <svg class="navIcon" aria-hidden="true">
            <use
              v-show="activeRouter=='/console/allianceMembers/bind'"
              xlink:href="#iconxingzhuang3-copy"
            />
            <use
              v-show="activeRouter!='/console/allianceMembers/bind'"
              xlink:href="#iconxingzhuang3-copy3"
            />
          </svg>
          <span slot="title">厂商绑定</span>
        </el-menu-item>
      </el-menu>
      <el-scrollbar class="menu-content" style="height:100%;box-sizing: border-box;">
        <el-breadcrumb class="breadcrumb-box" separator="/" style="margin:20px 0 0 20px">
            <el-breadcrumb-item
              class="bread-text"
              v-for='(key,index) in breadcrumb'
              :key='index'
              @click.native="(index+1 < breadcrumb.length ) && onNavigate(filterMenu(key))"
              style="cursor: pointer">
              {{filterMenu(key).name}}
            </el-breadcrumb-item>
          </el-breadcrumb>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta || !$route.meta.keepAlive"></router-view>
        <ICP bgcClor="#fafafa" color="#bdc2c7"></ICP>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import ICP from "@/components/ICP";
import { singleMembersSever } from "@/common/api-config";
export default {
  beforeRouteEnter(to, from, next) {
    if (to.name == "allianceMembers/bind") {
      //是去绑定厂商页
      window.$store
        .dispatch("membersStore/updateMembers", {})
        .then(isAllBind => {
          //拿厂商绑定数据
          next();
        });
    } else {
      //不是去绑定页
      window.$store
        .dispatch("membersStore/updateMembers", {})
        .then(isAllBind => {
          //拿厂商绑定数据
          if (!isAllBind) {
            //厂商没有全部绑定
            next({ name: "allianceMembers/bind" }); //跳转绑定厂商页
          } else {
            next();
          }
        });
    }
  },
  components: {
    ICP
  },
  created() {
    this.activeRouter = this.$route.meta.menu
      ? this.$route.meta.menu
      : this.activeRouter; //初始化选中路由
  },
  watch: {
    $route(to, from) {
      this.activeRouter = to.meta.menu ? to.meta.menu : to.path; //切换选中路由
    }
  },
  data() {
    return {};
  },
  computed: {
    breadcrumb() {
      let arr = []
      if(this.$route.meta.menuBreadcrumb){
        arr = this.$route.meta.breadcrumb;//['news','vot','detail']
      }else{
        arr = [this.$route.meta.title];//[title]
      }
      return arr;
    },
  },
  methods: {
    filterMenu (key) {
      if(this.$store.getters["breadcrumbStore/keyGetMenuBreadcrumb"](key)){
        return this.$store.getters["breadcrumbStore/keyGetMenuBreadcrumb"](key);
      }else{
        return {name:key}
      }
    },
    onNavigate(item) {
      // this.$router.push({
      //   path: item.path
      // })
      window.location.href=item.path;
    },
  }
};
</script>

<style scoped lang="scss">
/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-scrollbar__view {
  height: 100%;
}
/deep/ .el-breadcrumb__inner {
  display: inline-block;
  font-weight: 700;
  color: black;
  max-width: 120px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.consoleBox {
  padding-top: 80px;
  height: 100%; //减头355px(高度+底部)
  .menu-navBox {
    display: flex;
    height: 100%;
    .menu-nav {
      width: 250px;
      min-height: calc(100% - 80px); //减头355px(高度+底部)
      .menu-navItem {
        height: 90px;
        line-height: 90px;
        font-size: 16px;
        border-left: 15px solid #15111b;
        .navIcon {
          margin: 0 20px 0 10px;
          width: 1.5rem;
          height: 1.5rem;
          vertical-align: -0.3em;
          fill: currentColor;
          overflow: hidden;
        }
      }
      .is-active {
        background-color: #28262c !important;
        border-left: 15px solid #ded094;
      }
    }
    .menu-content {
      flex: 1;
      background: #fafafa;
      .breadcrumb-box{
        /deep/ .el-breadcrumb__inner{
          max-width: 200px;
        }
      }
    }
  }
}
</style>

