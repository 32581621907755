<template>
    <div class="ICP-centent" :style="{background:bgcClor,color:color}">
      Copyright © {{new Date().getFullYear()}} itgsa.com All rights reserved.
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a
        class="ICP-url"
        :style="{color:color}"
        href="http://beian.miit.gov.cn/"
      >京ICP备14038000号-18</a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <!-- <a
            class="ICP-url"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502038891"
      >京公网安备 11010502038891号</a>-->
    </div>
</template>

<script>
export default {
  props:['bgcClor','color'],
};
</script>

<style scoped lang="scss">
.ICP-centent {
    position: relative;
    margin: auto;
    line-height: 54px;
    color: #454751;
    background-color: #000000;
    z-index: 2;
    text-align: center;
    .ICP-url {
        color: #454751;
        text-decoration:none;
    }
}
</style>
